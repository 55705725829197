<template>
   <div class="container-fluid">
    <div class="page">

      <div v-if="loading" class="text-center">
        <div class="loader">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
   </div>
</template>

<script>

export default {
    name: "SocialAuth",
    data() {
        return {
            loading: true
        }
    },
    mounted() {
        if (this.$route.query.code) {
            const authType = this.$route.params.key;
            
            if (authType === 'google') {
                this.$http.get('/sanctum/csrf-cookie').then(() => {
                    this.$http.get('/social-auth/google/callback?code=' + this.$route.query.code + '&state=' + this.$route.query.state)
                        .then(() => {
                            window.location.href = '/';
                        });
                });
            } else  if (authType === 'patreon') {
                this.$http.get('/sanctum/csrf-cookie').then(() => {
                    this.$http.get('/social-auth/patreon/callback?code=' + this.$route.query.code + '&state=' + this.$route.query.state)
                        .then(() => {
                        window.location.href = '/';
                        });
                });
            }  
        } else {
            window.location.href = '/';
        }
    },
    methods: {
    
    }
}
</script>

<style lang="scss" scoped>

</style>
